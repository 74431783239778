<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>医患管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path:'/setDoctor' }">医生管理</el-breadcrumb-item>
      <el-breadcrumb-item>好转病例</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="addCase('new','')">添加</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="name" label="姓名"></el-table-column>
      <el-table-column label="年龄" prop="age"></el-table-column>
      <el-table-column label="性别" prop="sex"></el-table-column>
      <el-table-column label="疾病类别" prop="ill_name"></el-table-column>
      <el-table-column label="所患疾病" prop="ill"></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt">
            <p>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="addCase('bj',scope.row)">编辑 </el-button>
            </p>
            <p>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row,scope.$index)">删除 </el-button>
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      doc_id: "",
    };
  },
  mounted() {
    this.doc_id = this.$route.query.id;
    this.getdata(1, this.PageSize);
  },
  methods: {
    getdata(page, size) {
      this.axios
        .get(
          "/gu/get_gu_showcusts?page=" +
            page +
            "&size=" +
            size +
            "&doc_id=" +
            this.doc_id
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage, this.PageSize);
    },
    del(row, index) {
      this.$confirm("此操作将永久删除该病例, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/gu/delete_gu_showcust", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              // console.log(rs)
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    addCase(type, item) {
      if (type == "new") {
        this.$router.push({
          path: "/setDoctor/addCase/addCaseDetail",
          query: { type: type, doc_id: this.doc_id },
        });
      } else {
        this.$router.push({
          path: "/setDoctor/addCase/addCaseDetail",
          query: { type: type, doc_id: this.doc_id, id: item.id },
        });
      }
    },
  },
};
</script>
<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
  margin-top: 30px;
}
</style>